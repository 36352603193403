
import LendiEmbedder from './LendiEmbedder';

function onReady() {
  const embedder = new LendiEmbedder();
  embedder.createEmbeds();
}

if (document.readyState !== "loading") {
  onReady();
} else {
  document.removeEventListener("DOMContentLoaded", onReady);
  document.addEventListener("DOMContentLoaded", onReady);
}

document.removeEventListener("reload-widget", onReady);
document.addEventListener("reload-widget", onReady);
