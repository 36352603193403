const NUXT_2_WIDGETS = [
    'BaseInstallmentPicker',
    'BIComparerWidget',
    'BusinessInsiderWidget',
    'ComparerWidget',
    'LightComparerWidget',
    'MeetingPlannerWidget',
    'NaviboxBanner',
    'WideboardBanner',
    'HalfPageBanner',
    'SimulationFormSafeLoan',
    'SimulationFormMortgage',
];
const NUXT_3_WIDGETS = [
    'CreditWorthinessWidget',
    'CreditWorthinessOffersWidget',
    'MeetingPlannerWidget',
    'LightComparerWidget',
    'RefinanceWidget',
    'mbwWidget',
    'NaviboxBanner',
    'WideboardBanner',
    'SimulationFormBusinessInsider',
    'HalfPageBanner',
    'BaseInstallmentPicker',
    'SafeLoanWidget',
    'FlatForStartWidget',
    'FlatForStartFormWidget',
    'SimulationFormFlatForStart',
    'SafeLoanFormWidget',
    'MortgageComparerFormWidget',
    'SimulationFormSafeLoan',
    'SimulationFormMortgage',
    'expert-contact',
    'expert-contact-no-faq'
];
const NUXT3_QA_WIDGETS = [
    'CreditWorthinessWidget',
    'CreditWorthinessOffersWidget',
    'MeetingPlannerWidget',
    'LightComparerWidget',
    'RefinanceWidget',
    'mbwWidget',
    'NaviboxBanner',
    'WideboardBanner',
    'HalfPageBanner',
    'SimulationFormBusinessInsider',
    'BaseInstallmentPicker',
    'SafeLoanWidget',
    'FlatForStartWidget',
    'SafeLoanFormWidget',
    'FlatForStartFormWidget',
    'MortgageComparerFormWidget',
    'SimulationFormSafeLoan',
    'SimulationFormFlatForStart',
    'SimulationFormMortgage',
    'expert-contact',
    'expert-contact-no-faq'
];
// Wyżej nie podpisane widgety są w wersji Vue 2 (projekt embedded)
// https://github.com/finpack/embedded

const NUXT_2_URL = 'widgets';
const NUXT_3_URL = 'lendigety';

function extractUrl(url, widgetName) {
    const NO_CACHE = `no_cache=${Math.floor(Math.random())}`;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = NUXT_3_WIDGETS.includes(widgetName)
                ? `https://www.lendi.pl/${NUXT_3_URL}/${widgetName}?${NO_CACHE}`
                : NUXT_2_WIDGETS.includes(widgetName)
                    ? `https://www.lendi.pl/${NUXT_2_URL}/${widgetName}?${NO_CACHE}`
                    : `https://embedded.lendi.pl/?widget=${widgetName}&${NO_CACHE}`;
            break;
        case 'qa':
            let prefix = NUXT3_QA_WIDGETS.includes(widgetName)
                ? NUXT_3_URL
                : NUXT_2_URL;
            url = `https://qa.lendi.pl/${prefix}/${widgetName}?${NO_CACHE}`;
            break;
        case 'development':
            url = `http://localhost:8100/${widgetName}?${NO_CACHE}`;
            break;
    }
    return url;
}

class LendiEmbedder {
    counter = 0;

    _createIframe(options) {
      const widgetId = `widget-${Date.now().toString(36) + Math.random().toString(36).substr(2)}`;
      const parentLocation = encodeURIComponent(window.location.href);
      let url = '';
      url = extractUrl(url, options.widgetName);

      url = url + `&widgetId=${widgetId}&parentLocation=${parentLocation}${options.query}`;

        // Business insider ma sztywny kolor tła ustawiony na 23172C57
        if(options.widgetName === 'SimulationFormBusinessInsider') {
            url = url.replace('&secondaryColor=%23172C57', '')
            url = url.replace('&secondaryColor=#172C57', '')
        }
      const backlinkParagraph = document.createElement('p');
      backlinkParagraph.setAttribute('style', 'font-size: 12px; margin-top: 0px; margin-bottom: 0px;');
      backlinkParagraph.appendChild(document.createTextNode('Powered by '));
      let lendiNode = document.createElement('a');
      lendiNode.setAttribute('style', 'font-size: 12px;');
      lendiNode.setAttribute('href', 'https://www.lendi.pl');
      lendiNode.setAttribute('target', '_blank');
      lendiNode.appendChild(document.createTextNode('Lendi.pl'));
      backlinkParagraph.appendChild(lendiNode);

      const iframe = document.createElement('iframe');
      iframe.setAttribute('name', 'Lendi widget');
      iframe.setAttribute('title', 'Lendi Embedded widget');
      iframe.setAttribute('widget', options.widgetName);
      iframe.setAttribute('src', url);
      iframe.setAttribute('class', options.class || '');
      iframe.setAttribute('border', 'none');
      iframe.setAttribute('scrolling', options.scrollable ? 'auto' : 'no');
      iframe.setAttribute('loading', 'lazy');
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('height', options.height);
      iframe.setAttribute('id', widgetId);

      // wrap iframe and backlink in div
      const widgetElement = document.createElement('div');
      widgetElement.appendChild(iframe);
      widgetElement.appendChild(backlinkParagraph);

      const messageListener = (event) => {
        if (event.data.widgetId && event.data.widgetId === widgetId ||
          (!event.data.widgetId && event.data.widget && event.data.widget === options.widgetName)) {
          iframe.setAttribute('height', event.data.height);
        }
      };

      //Change height on event
      window.removeEventListener('message', messageListener);
      window.addEventListener('message', messageListener);

      return widgetElement;
    }

    createWidget(options) {
      const iframe = this._createIframe(options);
      // const backlink = this._createIframe(options);
      options.element.parentNode?.replaceChild(iframe, options.element);

      const iframeElement = iframe.querySelector('iframe');
      if (iframeElement) {
        new ResizeObserver((event) => {
          if (iframeElement.contentWindow) {
            iframeElement.contentWindow.postMessage({setHeight: true,}, '*');
          }
        }).observe(iframeElement);
      }
    }

    defaultHeight(widgetName) {
      return widgetName === "InstallmentGratka" ? "410px" : "285px";
    }

    async createEmbeds() {
      let allWidgetsOnPage = await this.waitForElementsAppear('.lendi-widget');
      allWidgetsOnPage.forEach((element) => {
        const widgetName = element.getAttribute('data-widget-name');
        const query = this.getDataQuery(element);
        this.createWidget({
          element,
          widgetName,
          height: element.getAttribute('data-height') || this.defaultHeight(widgetName),
          scrollable: element.getAttribute('data-scrollable'),
          dynamicHeight: element.getAttribute('data-dynamic'),
          class: element.getAttribute('data-class'),
          query
        });
      });
    }

    waitForElementsAppear(selector) {
      return new Promise(resolve => {
        if (document.querySelectorAll(selector).length) {
          return resolve(document.querySelectorAll(selector));
        }

        const observer = new MutationObserver(mutations => {
          if (document.querySelectorAll(selector).length) {
            resolve(document.querySelectorAll(selector));
            observer.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
  }

    getDataQuery(element) {
      // TODO: Dodać zabezpieczenie by usuwać niepotrzebne klucze.
      let dataQuery = element.getAttribute('data-query') || '';
      if (dataQuery) {
      } else {
      }
      return dataQuery;
    }
}

export default LendiEmbedder;
